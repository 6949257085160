import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VOverlay } from 'vuetify/lib/components/VOverlay';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VListItem,[_c(VOverlay,{staticClass:"d-flex align-center justify-center",attrs:{"absolute":"","value":_vm.loading}},[_c(VProgressCircular,{attrs:{"indeterminate":"","size":"32"}})],1),_c(VListItemAvatar,{staticClass:"mr-4"},[_c(VIcon,[_vm._v(_vm._s(_vm.userIcon))])],1),_c(VListItemContent,[_c(VListItemTitle,[_c('b',[_vm._v(_vm._s(_vm.user.email))]),_vm._v(" ("+_vm._s(_vm.user.username)+") ")]),_c(VListItemSubtitle,[_c('span',[_vm._v(_vm._s(_vm.user.role))]),_vm._v(" · "),(_vm.user.isActive)?_c('span',[_vm._v(" "+_vm._s(_vm.$t("activeUser.active"))+" "+_vm._s(_vm.activeUntil)+" ")]):_c('span',[_vm._v(_vm._s(_vm.$t("activeUser.inactive")))])])],1),(_vm.canManage && !_vm.user.emailVerified)?_c(VListItemAction,[_c(VTooltip,{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VBtn,_vm._g({attrs:{"icon":""},on:{"click":function($event){return _vm.$emit('verifyEmail')}}},on),[_c(VIcon,[_vm._v("mdi-email-check")])],1)]}}],null,false,60856841)},[_c('span',[_vm._v(_vm._s(_vm.$t("admin.verifyEmail")))])])],1):_vm._e(),_c('div',{staticClass:"mx-1"}),(_vm.canManage || _vm.isMe)?_c(VListItemAction,[_c(VTooltip,{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VBtn,_vm._g({attrs:{"icon":""},on:{"click":function($event){return _vm.$emit('permissions')}}},on),[_c(VIcon,[_vm._v("mdi-account-details")])],1)]}}],null,false,4254479107)},[_c('span',[_vm._v(_vm._s(_vm.$t("admin.permissions")))])])],1):_vm._e(),_c('div',{staticClass:"mx-1"}),(_vm.canManage)?_c(VListItemAction,[_c(VTooltip,{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VBtn,_vm._g({attrs:{"icon":""},on:{"click":function($event){return _vm.$emit('changeRole')}}},on),[_c(VIcon,[_vm._v("mdi-account-convert")])],1)]}}],null,false,148184412)},[_c('span',[_vm._v(_vm._s(_vm.$t("roles.change")))])])],1):_vm._e(),(_vm.canManage)?_c(VListItemAction,[_c(VTooltip,{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VBtn,_vm._g({attrs:{"icon":""},on:{"click":function($event){return _vm.$emit('toggleActive')}}},on),[_c(VIcon,[_vm._v(_vm._s(_vm.user.isActive ? "mdi-account-cancel" : "mdi-account-check"))])],1)]}}],null,false,3041431815)},[_c('span',[_vm._v(_vm._s(_vm.user.isActive ? _vm.$t("activeUser.deactivate") : _vm.$t("activeUser.activate")))])])],1):_vm._e()],1),(!_vm.isLast)?_c(VDivider,{staticClass:"my-1"}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }